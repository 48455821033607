<template>
    <div class="border">
        <div class="detailHeader">{{ title }}</div>
        <div class="wrap">
            <PayMentDetailItem
                v-for="(item, index) in bodyContent"
                :key="item.label"
                :label="item.label"
                :value="item.value"
                :width="item.width"
                :top="index > 2 ? 20 : 0"
            />
        </div>
    </div>
</template>

<script>
import PayMentDetailItem from "./PayMentDetailItem.vue";
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        bodyContent: {
            type: Array,
            default: [],
        },
    },
    components: {
        PayMentDetailItem,
    },
    data() {
        return {};
    },
};
</script>
<style scoped>
.border {
    border: 1px solid #d3d3d3;
}

.detailHeader {
    line-height: 36px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 20px;
    background: #f2f2f2;
    border-left: 4px solid var(--RED-C11C20);
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 20px;
}
</style>
